<template>
  <div>
    <div class="desktop-header">
    <el-menu
        :default-active="activeIndex"
        class="el-menu-demo header-menu py-10"
        mode="horizontal"
        :ellipsis="false"
        :router="true"
        @select="handleSelect">

      <div class="d-flex flex-direction-column-m align-items-center justify-content-between mx-100 w-100">
        <router-link to="/" class="brand-color">
          <img :src="setting.setting.image.full_url" v-if="setting.setting.image" alt="BD Royel Culture" class="logo">
          <h3 v-else>BD ROYAL CULTURE</h3>
        </router-link>
        <el-form
            label-position="top"
            ref="search"
        >
          <el-input
          @keyup.enter="searchProduct"
              v-model="search"
              class="search_input m-2"
              size="large"
              placeholder="Example: Shirt, T-shirt"
              suffix-icon="Search"
          />
        </el-form>
        <el-sub-menu index="2" v-if="loggedIn">
          <template #title>
            <el-avatar v-if="authUser.image" :size="40" class="user_avatar">
              <img
                  :src="userPhoto(authUser.image)" alt="Bd Royel Culture"
              />
            </el-avatar>
            <el-avatar v-else :size="40" class="user_avatar">
              <img
                  src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" alt="Bd Royel Culture"
              />
            </el-avatar>
            <p style="margin: 0px 20px">{{ authUser ? authUser.name : 'profile' }}</p>
          </template>
          <el-menu-item index="/user/dashboard">Dashboard</el-menu-item>
          <el-menu-item index="/user/order/list">Order List</el-menu-item>
          <el-menu-item index="login">Logout</el-menu-item>
        </el-sub-menu>
        <el-button v-else @click="loginButton" class="brand-bg color_white" round>MY ACCOUNT</el-button>
      </div>
    </el-menu>
    <SubHeader/>
    </div>

    <div class="mobile-header">
      <div class="d-flex justify-content-between align-items-center">
        <el-button type="primary" @click="dialog = true" circle><el-icon><Operation /></el-icon></el-button>
        <router-link to="/" class="brand-color">
          <!--          <img :src="require('@/assets/images/logo-light.png')" alt="BD Royel Culture" class="logo">-->
          <h3>BD ROYAL CULTURE</h3>
        </router-link>
        <el-button  circle @click="toggleSearchInput"><el-icon><Search /></el-icon></el-button>
      </div>
      <br>
      <el-input
      @keyup.enter="searchProduct"
              v-model="search"
              class="search_input m-2"
              size="large"
              placeholder="Example: Shirt, T-shirt"
              suffix-icon="Search"
       v-show="showSearchInput" 
       clearable>
      </el-input>

    </div>

    <SidebarShopingCart/>
    <el-drawer
        v-model="dialog"
        title="Menu"
        direction="rtl"
        size="70%"
      >
       <MobileHeader/>
      </el-drawer>
  </div>
</template>

<script>
import SidebarShopingCart from '@/components/SidebarShopingCart';
import { mapGetters } from 'vuex';
import SubHeader from './SubHeader';
import MobileHeader from './MobileHeader';
export default {
  name: 'Header',
  components: {
    SidebarShopingCart,
    SubHeader,
    MobileHeader
  },

  data() {
    return {
      activeIndex: '1',
      search: '',
      dialog: false,
      showSearchInput: false, // Track the visibility of the input box
      searchInput: '' // Store the value of the input
    };
  },
  computed: {
    // ...mapGetters('auth', ['authUser', 'loggedIn']),
    ...mapGetters({
      authUser: 'auth/authUser',
      loggedIn: 'auth/loggedIn',
      setting: 'setting/get_setting',
    })
  },
  methods: {
    handleSelect() {
    },
    loginButton() {
      this.$router.push('/login');
    },
    searchProduct() {
      this.$router.push({
        path: '/products',
        query: { q: this.search },
      });

    },
    handleInput(event) {
      event.stopPropagation();
    },
    toggleSearchInput() {
      // Toggle the visibility of the input box
      this.showSearchInput = !this.showSearchInput;
      // Clear the input when hiding the search box
      if (!this.showSearchInput) {
        this.searchInput = '';
      }
    }
  },
};
</script>

<style scoped>

</style>
<style>
.search_input {
  width: 500px;
}

.el-input__wrapper {
  border-radius: 30px !important;
}
</style>
